<template>
	<div class="mine">
		<div class="person-card">
			<div class="card">
				<van-image :src="require('@/assets/cardbg.png')" />
			</div>
			<div class="info">
				<van-row type="flex" justify="space-between">
					<van-col class="name" span="8">
						<div>华信合科技</div>
						<div>
							<div>{{userData.Name}}</div>
							<div>{{userData.DepartmentName}} | {{userData.Position}}</div>
						</div>
					</van-col>
					<van-col class="avatar" span="8">
						<van-image
							width="60"
							height="60"
							:src="userData.UserPhoto"
						/>
					</van-col>
				</van-row>
			</div>
		</div>
		<div class="cell">
			<van-cell title="打卡记录" icon="clock-o" is-link to="/SmartClassroom/Attendance" />
		</div>
		<div style="margin-top:37px">
			<van-divider>更多功能，敬请期待</van-divider>
		</div>
	</div>
</template>
<script>
import { Image as VanImage, Cell, Divider,Toast } from 'vant'
import {GetMineById} from "@/api/PeopleManagement/people.js"
export default {
	components: {
		[VanImage.name]: VanImage,
		[Cell.name]: Cell,
		[Divider.name]: Divider,
	},
	data() {
		return {
			userId:JSON.parse(this.defineMethods.getCookie('LoginUserInfo')).id,
			userData:{
				Name:null,
				DepartmentName:null,
				Position:null,
				UserPhoto:null
			},
		}
	},
	methods: {
		async GetById(id){
            let result= await GetMineById(id);
			if (result.ResultCode === 200) {
				this.userData = result.Data
			} else {
				Toast.fail('获取信息错误')
			}
		},
	},
	computed: {},
	mounted() {
		// console.log(this.userId);
		// this.GetById(this.userId);
	},
	created(){
		this.GetById(this.userId);
	}
}
</script>
<style lang="scss" scoped>
@import '../../style/public.scss';
.mine {
	width: 100%;
	height: 100%;
	background: $bgColor;
	.person-card {
		position: relative;
		background: #fff;
		width: 100%;
		height: 191px;
		.card {
			position: absolute;
			top: 17px;
			left: 17px;
			z-index: 5;
			width: 341px;
			height: 137px;
			border-radius: 10px;
			background: linear-gradient(135deg, #f8962b 0%, #f6a606 100%);
		}
		.info {
			position: absolute;
			top: 17px;
			left: 17px;
			z-index: 8;
			width: 341px;
			height: 137px;
			.name {
				margin-top: 16px;
				margin-left: 30px;
				font-size: 14px;
				font-weight: 500;
				color: #ffffff;
				> div:first-child {
					white-space: nowrap;
				}
				> div:last-child {
					margin-top: 40px;
					color: #ffffff;
					> div:first-child {
						font-size: 18px;
						font-weight: 500;
						white-space: nowrap;
					}
					> div:last-child {
						font-size: 12px;
						font-weight: 400;
						white-space: nowrap;
					}
				}
			}
			.avatar {
				width: 60px;
				height: 60px;
				overflow: hidden;
				border-radius: 10px;
				margin-right: 16px;
				margin-top: 16px;
			}
		}
	}
	.cell {
		margin-top: 4px;
		.van-cell {
			color: $titleColor;
		}
		.van-cell__right-icon {
			color: $titleColor;
		}
		.van-cell__title {
			span {
				margin-left: 10px;
			}
		}
	}
	.van-divider {
		color: #c8c8c8;
	}
}
</style>
